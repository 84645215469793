<template>
    <div class="ds-container">
      <section class="ds-header">
        <div class="ds-title">
          <h2>Lotes a enviar</h2>
        </div>
        <div class="btn-title-container">
          <b-button variant="primary" v-if="selected.length" @click="generateFile">
            <Download class="download-icon" />
            Download
          </b-button>
          <b-button
            variant="outline-primary"
            v-if="selected.length"
            @click="sentBatches"
          >
            Definir lote(s) como enviados
          </b-button>
        </div>
      </section>
      <section>
        <LotsFilters @change="onChangeFilter" class="no-print" />
      </section>
      <section>
        <LotsToSendTable
          :tableRows="tableRows"
          @delete-batch="removeItem"
          @selected-batches="selectedBatches"
          :getTypes="getTypes"
          @onOrdenate="handleOrdenate"
          :openHistoric="openHistoric"
          :getGuide="getGuide"
        />
        <NoFiltersResults
          v-if="!tableRows.length"
          message="Os filtros aplicados não geraram nenhum resultado."
        />
        <div class="pagination-position">
          <b-pagination
            size="sm"
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
          />
        </div>
      </section>

      <TissGuideAuditModal :tissGuideAudits="tissGuideAudits" />

      <TissGuidesModalsContainer
        :patient="patient"
        :tissGuide="tissGuide"
        :susGuide="susGuide"
        :clinicHealthPlan="clinicHealthPlan"
        :appointmentId="tissGuide.appointment_id"
        :getLastTissGuide="getLastTissGuide"
        :clearTissGuide="clearTissGuide"
        :required_fields="tissRequiredCustomField"
        :readonly="false"
        :setTissGuide="setTissGuide"
        :getGuide="getGuide"
      />
    </div>
  </template>
  
  <script>
  import { saveAs } from 'file-saver'
  import { parseNumberToMoney } from '@/utils/moneyHelper'
  import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
  import { getTypes, handleGuideUpdate } from '@/utils/invoicingSusHelper'
  import { SusBatch } from '@/modules/sus/manageGuides/utils/statuses'
  import api from '@/modules/sus/api'

  export default {
    components: {
      Download: () => import('@/assets/icons/download.svg'),
      LotsFilters: () => import('@/components/Sus/Generic/Filters/LotsToSendFilter'),
      LotsToSendTable: () => import('@/components/Sus/Table/LotsToSendTable'),
      NoFiltersResults: () => import('@/components/General/noFilterResults'),
      TissGuideAuditModal: () => import('@/components/Sus/Modal/TissGuideAuditModal.vue'),
      TissGuidesModalsContainer: () => import('@/layouts/InvoicingTiss/TissGuide/TissGuidesModalsContainer.vue')
    },
    props: {
      print: Function
    },
    data() {
      return {
        clinic: getCurrentClinic(),
        user: getCurrentUser(),
        currentPage: 1,
        perPage: 10,
        totalItems: 0,
        tableRows: [],
        selected: [],
        filters: {
          orderBy: {
            column: 'created_at',
            order: 'desc'
          }
        },
        tissGuideAudits: [],

        patient: {},
        tissGuide: {},
        clinicHealthPlan: {},
        tissRequiredCustomField: {}
      }
    },
    computed: {
      selectedIds() {
        return this.selected.map(selected => selected.id)
      }
    },
    async created() {
      await this.getBatches()
    },
    methods: {
      getTypes,
      parseNumberToMoney,
      async getBatches() {
        const isLoading = this.$loading.show()
        const filters = this.filters
        filters.type = filters.type?.value

        try {
          this.tableRows = []
          const { data } = await api.getSusBatches(this.clinic.id, this.currentPage,{
            filters: this.filters,
            type: 'A enviar'
          })
          this.currentPage = data.current_page
          this.perPage = data.per_page
          this.totalItems = data.total
  
          this.fillRowData(data.data)
        } catch (error) {
          this.$toast.error(error.message)
        } finally {
          isLoading.hide()
        }
      },
      async sentBatches() {
        const isLoading = this.$loading.show()
        try {
          const selectedRequest = this.selected.map(selected => {
            return {
              id: selected.id,
              status: SusBatch.BATCH_STATUS_SENT,
              responsible_id: this.user.id,
              guide_type: selected["Tipo de Lote"].includes("BPA") 
                ? "BPA" 
                : "APAC"
            }
          })
          const { data } = await api.updateManySusBatches({
            data: selectedRequest
          })
          this.tableRows = this.tableRows.filter(row => {
            return !data.includes(row.id)
          })
          this.selected = []
          this.$router.push('lotes-enviados')
          this.$toast.success('Lotes movidos para aba Lotes enviados')
        } catch (error) {
          this.$toast.error(error.message)
        } finally {
          isLoading.hide()
        }
      },
      parseDate(date) {
        return this.moment(date).format('DD/MM/YYYY')
      },
      fillRowData(batches) {
        console.log(batches, 'batces');
        batches.forEach(data => {
          const row = {
            id: data.id,
            Checked: false,
            Criação: this.parseDate(data.created_at),
            'Tipo de Lote': data.type === 'App\\Models\\Sus\\ApacGuide' ? 'APAC' : 'BPA',
            'N° Lote': data.number,
            'N° guias BPA': data.type === 'App\\Models\\Sus\\ApacGuide' ? '-' : data.guides_count,
            'N° folha(s)': '-',
            'N° guias APAC': data.type === 'App\\Models\\Sus\\ApacGuide' ? data.guides_count : '-',
            'Faturado': '0',
            'Conferido': {
              Porcentagem:
              (data.guides_count > 0 ? ((data.total_guides_conferred / data.guides_count).toFixed(2) * 100) : 0) + 
                '%',
              Conferidas: data.total_guides_conferred,
              'Não conferidas':
                data.guides_count - data.total_guides_conferred
            },
            Options: ''
          }

          this.tableRows.push(row)
        })
      },
      removeItem(id) {
        this.tableRows.splice(
          this.tableRows.findIndex(item => item.id === id),
          1
        )
      },
      selectedBatches(value) {
        this.selected = value
      },
      async onChangeFilter(filter) {
        this.filters = filter
        await this.getBatches()
      },
      async generateFile() {
        const isLoading = this.$loading.show()
        try {
          const { data } = await this.api.susBatchMAI({
            ids: this.selectedIds,
            clinicId: this.clinic.id
          })
          data.map(async data => {
            const blob = new Blob([data.file], { type: 'text/plain;charset=utf-8' })
            saveAs(blob, data.filename)
          })
        } catch (error) {
          if(error.message.includes('code')) this.$toast.warning('MAI não gerado! Existem guias sem procedimentos preenchidos dentro do lote.') 
          else this.$toast.error(error.message)
        } finally {
          isLoading.hide()
        }
      },
      async handleOrdenate(orderBy) {
        this.$set(this.filters, 'orderBy', orderBy)
        await this.getBatches()
      },
      async openHistoric(tissGuideId) {
         await this.getTissGuideAudits(tissGuideId)
         this.$bvModal.show('tiss-guide-audit-modal')
      },
      async getTissGuideAudits(tissGuideId) {
         const isLoading = this.$loading.show()
         try {
           const response = await this.api.getSusGuideAudits(tissGuideId)
           this.tissGuideAudits = response.data
         } catch (error) {
           this.$toast.error(error.message)
         } finally {
           isLoading.hide()
         }
      },
      async getGuide(tissGuide, notOpen) {
        const isLoading = this.$loading.show()
        try {
          const id = tissGuide?.id ?? tissGuide
          const response = await this.api.getSusGuideById(id);
          this.susGuide = response.data;
          if (notOpen) return
  
          this.patient = this.susGuide.patient
          
          this.handleGuide(this.susGuide.guide_type)
        } catch (error) {
          this.$toast.error(error.message)
        } finally {
          isLoading.hide()
        }
      },
      handleGuide(type) {
        const modal = handleGuideUpdate(type)
        this.$bvModal.show(modal)
      },
      setTissGuide(tissGuide){
        this.tissGuide = null
        this.tissGuide = tissGuide
      },
      clearTissGuide() {
        this.tissGuide = {}
      },
      getHtmlTable(){
        let tablesHtml = `
        <div style="text-align: left; padding: 10px;">
          Essa ação é definitiva, e você <strong>não poderá mais editar as guias deste(s) lote(s)</strong>. Deseja realmente definir esse(s) lote(s) como enviado(s)?
        </div>
        <div class="custom-batch-review-tables-container">`
        this.selected.forEach(item => {
          tablesHtml += `<table class="custom-batch-review-table">
            <thead>
              <tr class="healthplan-tr">
                <th colspan="7">
                  <div class="healthplan-info">
                    <div class="d-flex" style="justify-content: space-between">
                      <span id="healthplan-label">
                        Convênio: 
                      </span>
                    </div>
                    <span>${item.Convênio}</span>
                  </div>
                </th>
              </tr>
              <tr class="batch-tr">
                <th>Nº Lote</th>
                <th>N॰ Fatura</th>
                <th>Tipo de guia</th>
                <th>Nº Guias</th>
                <th>Versão TISS</th>
                <th>Valor total</th>
                <th>Conferido</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>${item['Nº Lote']}</td>
                <td>${item['Nº Fatura']}</td>
                <td>${item['Tipo de guia']}</td>
                <td>${item['Nº guias']}</td>
                <td>${item['Versão TISS']}</td>
                <td>${item['Valor total']}</td>
                <td>
                  <span :id="'popover-' + index" style="border-radius: 30px; padding: 4px 10px; font-weight: 700; ${this.getColorStyles(item.Conferido.Porcentagem)}">
                    ${item.Conferido.Porcentagem}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>`
        });

        tablesHtml += '</div>';

        return tablesHtml;
      },
      getColorStyles(percentage) {
      const value = parseInt(percentage.replace(/[^0-9]/g, ''));
        if (value < 10) {
          return 'background-color: var(--light-orange-200); color: var(--states-error);';
        } else if (value < 20) {
          return 'background-color: #fff3e1; color: var(--orange);';
        } else if (value < 50) {
          return 'background-color: #fff6d4; color: #bc8212;';
        } else if (value < 80) {
          return 'background-color: var(--blue-100); color: var(--blue-500);';
        } else {
          return 'background-color: var(--states-success-soft-green); color: var(--states-success-dark-green);';
        }
      },
    },
    watch: {
      async currentPage() {
        await this.getBatches()
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .ds-container {
    padding: 30px 10px;
    .ds-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 0 0;
    }
    .ds-title {
      h2 {
        display: inline-flex;
        align-items: center;
        font-family: 'Red Hat Display';
        font-weight: 500;
        font-size: 18px;
      }
  
      .icon {
        height: 24px;
        width: 24px;
        margin-left: 5px;
        stroke: var(--neutral-500);
      }
    }
  
    .btn-title-container {
      button {
        margin-left: 15px;
      }
  
      .download-icon {
        height: 16px;
        width: 16px;
        margin-right: 7px;
        margin-bottom: 3px;
      }

    }
  }
  </style>
  